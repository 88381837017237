import { atom, createStore } from 'jotai'

export const myStore = createStore();

const user = localStorage.user ? JSON.parse(localStorage.user) : null

export const userState = atom(user)

export const loginModalState = atom(false)

export const exchangeModalState = atom(false)