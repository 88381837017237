import { useState } from 'react'
import useAuthAction from '@/hooks/useAuthAction'
import { toast } from 'react-toastify'
import { orderActivate } from '@/api/course'
import { useAsyncFn, useToggle } from 'react-use'
import Modal from '@/components/Modal'
import { useAtom } from 'jotai'
import { exchangeModalState } from '@/store/global'

export default function Index({ ret }: { ret?: () => void }) {
  const [exchangeModal, setExchangeModal] = useAtom(exchangeModalState)
  const [code, setCode] = useState('')

  const [orderActivateData, orderActivateFetch] = useAsyncFn<() => Promise<any>>(async () => {
    if (code === '') {
      toast('请输入兑换码')
      return ''
    }
    const res = await orderActivate({ code })
    setCode('')
    if (res.code !== 200) {
      toast(res.msg)
      return ''
    }
    toast.success(res.msg)
    setExchangeModal(false)
    ret && ret()
    return res.data
  }, [code])

  return (
    <Modal show={exchangeModal} cancel={() => setExchangeModal(false)}>
      <div className="bg-white rounded-[20px] w-[380px] h-[182px] px-[21px] pt-[23px]">
        <div className="text-[18px] text-black leading-none mb-[19px]">请输入随盒兑换码</div>
        <input
          className="w-full h-[40px] rounded-[5px] border-[#535353] border-[1px] border-solid text-[16px] px-[10px] mb-[20px]"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          type="text"
          name=""
          id=""
        />
        <div className="flex justify-between items-center">
          <div className="w-[160px] h-[40px] rounded-full bg-[#C9C9C9] text-[18px] text-white flex_center anim_btn" onClick={() => setExchangeModal(false)}>
            取消
          </div>
          <div className="w-[160px] h-[40px] rounded-full submit_btn text-[18px] text-white flex_center submit_btn anim_btn" onClick={orderActivateFetch}>
            兑换
          </div>
        </div>
      </div>
    </Modal>
  )
}
