import { useLocation, useNavigate } from 'react-router-dom'
import boardImg from '@/assets/images/nav/board.png'
import courseImg from '@/assets/images/nav/course.png'
import course1Img from '@/assets/images/nav/course1.png'
import homeImg from '@/assets/images/nav/home.png'
import home1Img from '@/assets/images/nav/home1.png'
import myImg from '@/assets/images/nav/my.png'
import my1Img from '@/assets/images/nav/my1.png'

const showNav = ['/course', '/my', '/']

export default function Index() {
  const location = useLocation()
  const navigate = useNavigate()
  // console.log(location)
  return showNav.includes(location.pathname) ? (
    <div className="w-screen h-[48px] fixed z-50 bottom-0 flex">
      <div className="w-full flex justify-around items-center bg-white text-[10px]">
        <div className="flex_center flex-col w-[55px]" onClick={() => navigate('/')}>
          <img className={`${location.pathname === '/' ? 'w-[55px] h-[55px] mt-[-20px] mb-[-9px]' : 'mb-[5px] w-[25px] h-[21px]'}`} src={location.pathname === '/' ? home1Img : homeImg} alt="" />
          <div className={`z-20 ${location.pathname === '/' ? 'text-[#F79D16]' : 'text-[#B1A6BA]'}`}>首页</div>
        </div>
        <div className="flex_center flex-col w-[55px]" onClick={() => navigate('/course')}>
          <img
            className={`${location.pathname === '/course' ? 'w-[55px] h-[55px] mt-[-20px] mb-[-9px]' : 'mb-[5px] w-[25px] h-[21px]'}`}
            src={location.pathname === '/course' ? course1Img : courseImg}
            alt=""
          />
          <div className={`z-20 ${location.pathname === '/course' ? 'text-[#F79D16]' : 'text-[#B1A6BA]'}`}>课程</div>
        </div>
        <div className="flex_center flex-col w-[55px]" onClick={() => navigate('/board')}>
          <img className="mb-[5px] w-[25px] h-[21px]" src={boardImg} alt="" />
          <div className="text-[#B1A6BA]">创作</div>
        </div>
        <div className="flex_center flex-col w-[55px]" onClick={() => navigate('/my')}>
          <img className={`${location.pathname === '/my' ? 'w-[55px] h-[55px] mt-[-20px] mb-[-9px]' : 'mb-[5px] w-[25px] h-[21px]'}`} src={location.pathname === '/my' ? my1Img : myImg} alt="" />
          <div className={`z-20 ${location.pathname === '/my' ? 'text-[#F79D16]' : 'text-[#B1A6BA]'}`}>我的</div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}
