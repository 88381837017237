import React, { Suspense, memo, useEffect, useState } from 'react'
import { createBrowserRouter, RouterProvider, Navigate, useLocation, RouteObject, useRouteError } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import router, { ignoreAuthPath, ignorePath, routerType } from './conf'
import ErrorAuth from '../pages/error/auth'

const modules = import.meta.glob('../pages/**/*')

export const routerAnimate = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
  transition: {
    duration: 0.15,
    type: 'spring',
    damping: 35,
    stiffness: 600
  }
}

// 守卫
const RequireAuth = ({ component }: { component: string }) => {
  let location = useLocation()
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (!ignorePath.includes(location.pathname) && id) {
      // findAuthority()
      //   .then((res) => {
      //     if (res.code === 0) {
      //       setAuthority(res.data)
      //     } else {
      //       // message.error(res.msg)
      //     }
      //   })
      //   .finally(() => {
          setReady(true)
      //   })
    } else {
      setReady(true)
    }
  }, [])

  // console.info('页面：', location.pathname, authority, ready)
  const id = localStorage.id || false
  // 注意，如果路由中没有「/」的声明，一定要排除，不然Navigate受两次跳转会失效
  // if (!ignorePath.includes(location.pathname) && !id) {
  if (false) {
    return <Navigate to={`/login?from=${location.pathname}`} replace />
  } else {
    // if (!ready) {
      // return (
      //   <div className="w-full h-full flex justify-center items-center">
      //     {/* <BiLoader className='animate-spin text-[30px]' /> */}
      //     <svg className="animate-spin -ml-1 mr-3 h-10 w-10 text-[#999]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      //       <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      //       <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      //     </svg>
      //   </div>
      // )
      // return <span>loading...</span>
    // } else if (!ignoreAuthPath.includes(location.pathname) && (!authority || !authority.includes(`${location.pathname}:0`))) {
    //   return <ErrorAuth />
    // } else {
      const Component = React.lazy(modules[component] as any)
      return (
        <Suspense>
          <AnimatePresence
            mode="wait"
            initial={true}
            onExitComplete={() => {
              if (typeof window !== 'undefined') {
                window.scrollTo({ top: 0 })
              }
            }}
          >
            <Component key={location.pathname} />
          </AnimatePresence>
        </Suspense>
      )
    // }
  }
}

function ErrorBoundary() {
  let error = useRouteError();
  console.error('AAA',error);
  // Uncaught ReferenceError: path is not defined
  return <div>Dang!</div>;
}

// 循环
const RouterTree = (tree: routerType[]): RouteObject[] => {
  return tree.map(({ path, component, children }) => {
    if (children && children.length) {
      return {
        path,
        element: component,
        children: RouterTree(children)
      }
    } else {
      return {
        path,
        element: <RequireAuth component={component as string} />,
        errorElement: <ErrorBoundary/>
      }
    }
  })
}

// 路由
// 可在非组件中直接使用 routers.navigate('/') 跳转
export const routers = createBrowserRouter([...RouterTree(router)])

// 动画
export const AnimatedRoutes = memo(() => {
  return <RouterProvider router={routers} />
})
