import request, { Res } from '@/utils/request'
import { headerInfo } from '@/utils/tool'
import md5 from 'md5'

// 获取验证
export const sendCode = (data: any) => {
  const timestamp = new Date().getTime()
  const sign = md5(`${headerInfo.deviceId}${headerInfo.appVersion}${headerInfo.deviceType}${data.phone}${timestamp}${import.meta.env.VITE_APP_APPSECRET}`)
  return request.post<Res, Res>(`/metaleap-api/v1/login/sendCode`, { ...data, timestamp, sign })
}

// 登录
export const codeLogin = (data: any) => {
  const timestamp = new Date().getTime()
  const sign = md5(`${headerInfo.deviceId}${headerInfo.appVersion}${headerInfo.deviceType}${data.code}${data.phone}${timestamp}${import.meta.env.VITE_APP_APPSECRET}`)
  return request.post<Res, Res>(`/metaleap-api/v1/login/codeLogin`, { ...data, timestamp, sign })
}

// 获取 sts
export const getSts = () => {
  return request.get<Res, Res>(`/metaleap-api/v1/oss/anon/sts`)
}
