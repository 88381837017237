/**
 * 主题配置
 */

// 黑
export const darkTheme = {
  // bgColor: "#2f3640",
  textColor: '#000'
  // accentColor: "#fbc531",
  // cardBgColor: "#171b20",
  // cardBgHovColor: "#222",
}

// 亮
export const lightTheme = {
  // bgColor: "#dcdde1",
  textColor: 'red'
  // accentColor: "#fbc531",
  // cardBgColor: "#fefefe",
  // cardBgHovColor: "#eee",
}
