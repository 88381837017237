import request, { Res } from '@/utils/request'

// 课程列表
export const courseList = () => {
  return request.get<Res, Res>(`/metaleap-api/v1/course/anon/list`)
}

// 兑换
export const orderActivate = (data: any) => {
  return request.post<Res, Res>(`/metaleap-api/v1/course/order/activate`, data)
}
