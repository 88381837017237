import { Outlet } from 'react-router-dom'
import Login from '../Login'
import Nav from '../Nav'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useEffect, useState } from 'react'
import { useInterval } from 'react-use'
import { defAnimate } from '../Modal'
import { AnimatePresence, motion } from 'framer-motion'
import hspImg from '@/assets/images/global/hsp.jpg'
import bgImg from '@/assets/images/global/bg.png'
import qrLiveImg from '@/assets/images/global/qr-live.png'
import qrTestImg from '@/assets/images/global/qr-test.png'
import { getDeviceType } from '@/utils/tool'
import Video from '../Video'
import Exchange from '../Exchange'

const eqImg = import.meta.env.VITE_APP_ENV === 'test' ? qrTestImg : qrLiveImg

export default () => {
  const [orientation, setOrientation] = useState(1)
  const [deviceType, setDeviceType] = useState(getDeviceType())

  const screenOrientationListener = () => {
    try {
      const ih = window.innerHeight
      const iw = window.innerWidth
      if (iw > ih) {
        setOrientation(1)
      } else {
        setOrientation(0)
      }
    } catch (e) {}
  }

  // 轮询详情
  useInterval(screenOrientationListener, 100)

  if (typeof document === 'undefined') {
    return <div />
  }
  return (
    <>
      <AnimatePresence>
        {!orientation ? (
          <motion.div {...defAnimate} className="w-screen h-screen fixed z-[999999] top-0 left-0 bg-white flex_center flex-col">
            <img src={hspImg} alt="" className="w-[120px] h-[120px]" />
            <div className="text-[22px] mt-[20px]">请使用横屏浏览</div>
          </motion.div>
        ) : null}
        {/* {deviceType === 'desktop' ? (
          <motion.div {...defAnimate} className="w-screen h-screen fixed z-[999999] top-0 left-0 bg-white flex_center flex-col">
            <img src={bgImg} alt="" className="absolute top-0 left-0 w-full h-full" />
            <div className="w-[276px] h-[279px] relative z-10 rounded-[8px] bg-white flex_center flex-col">
              <div className="text-[14px]">当前功能不支持电脑端使用</div>
              <div className="text-[14px]">请在平板设备打开网页获得最佳体验</div>
              <img src={eqImg} alt="" className="w-[114px] h-[114px] mt-[20px]" />
              <div className="text-[13px]">平板设备扫码体验</div>
            </div>
          </motion.div>
        ) : null} */}
        {/* {deviceType === 'mobile' ? (
          <motion.div {...defAnimate} className="w-screen h-screen fixed z-[999999] top-0 left-0 bg-white flex_center flex-col">
            <img src={bgImg} alt="" className="absolute top-0 left-0 w-full h-full" />
            <div className="w-[598px] h-[604px] relative z-10 rounded-[13px] bg-white flex_center flex-col">
              <div className="text-[31px]">当前功能不支持手机端使用</div>
              <div className="text-[31px]">请在平板设备打开网页获得最佳体验</div>
              <img src={qrLiveImg} alt="" className="w-[248px] h-[248px] mt-[71px]" />
              <div className="text-[28px]">平板设备扫码体验</div>
            </div>
          </motion.div>
        ) : null} */}
      </AnimatePresence>
      <Outlet />
      <Login />
      <Nav />
      <Video />
      <Exchange />
    </>
  )
}
