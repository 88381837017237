import startPlayImg from '@/assets/images/course/startPlay.png'
import startPauseImg from '@/assets/images/course/startPause.png'
import { useEffect, useRef } from 'react'
import { SimplePlayer, Util } from 'xgplayer'
import MobilePreset from 'xgplayer/es/presets/mobile'
import 'xgplayer/dist/index.min.css'
import './index.css'
import CtrlPlugin from './ctrlPlugin'
import TryPlugin from './tryPlugin'
import useGetState from '@/hooks/useGetState'
import { useLocation, useNavigate } from 'react-router-dom'
import Draggable from 'react-draggable'
import { useUpdateEffect } from 'react-use'
import { useGetAtom } from '@/hooks/useGetAtom'
import { checkCourseState, checkVideoState, checkIndexState } from '@/store/video'

SimplePlayer.defaultPreset = MobilePreset

export default function Index() {
  const playerRef = useRef<SimplePlayer | null>(null)
  const [fullScreen, setFullScreen, getFullScreen] = useGetState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [boxPosition, setBoxPosition] = useGetState<DOMRect | undefined>(undefined)
  const [isCourse, setIsCourse] = useGetState(false)
  const [minFloat, setMinFloat, getMinFloat] = useGetState(false)
  const [checkCourse, setCheckCourse, getCheckCourse] = useGetAtom(checkCourseState)
  const [checkVideo, setCheckVideo, getCheckVideo] = useGetAtom(checkVideoState)
  const [checkIndex, setCheckIndex, getCheckIndex] = useGetAtom(checkIndexState)

  useEffect(() => {
    playerRef.current = new SimplePlayer({
      url: '',
      id: 'mse',
      autoplay: false,
      replay: false,
      controls: {
        mode: 'flex'
      },
      // enableVideoDbltouch: true,
      // closeFocusVideoFocus: false,
      inactive: 99999999,
      commonStyle: {
        playedColor: '#0078FF',
        sliderBtnStyle: {
          background: '#0078FF30'
        }
      },
      mobile: {
        disableGesture: true
      },
      closeVideoClick: true,
      volume: 0.3,
      playbackRate: [],
      // fullscreen: false,
      // poster: '//lf3-static.bytednsdoc.com/obj/eden-cn/nupenuvpxnuvo/xgplayer_doc/poster.jpg',
      playsinline: true,
      icons: {
        startPlay: () => {
          const dom = Util.createDom('div', `<div class='play-box'><img src="${startPlayImg}"/></div>`, {}, 'customclass')
          return dom
        }, // 通过function方式 返回一个dom
        startPause: `<div class='customclass'><div class='play-box'><img src="${startPauseImg}"/></div></div>` // 直接html代码
      },
      start: {
        disableAnimate: true
      },
      // thumbnail: {
      //   pic_num: 44,
      //   width: 160,
      //   height: 90,
      //   col: 10,
      //   row: 10,
      //   urls: ['//lf3-static.bytednsdoc.com/obj/eden-cn/nupenuvpxnuvo/xgplayer_doc/xgplayer-demo-thumbnail.jpg']
      // },
      danmu: {
        comments: [
          {
            duration: 15000,
            id: '1',
            start: 3000,
            txt: '长弹幕长弹幕长弹幕长弹幕长弹幕',
            style: {
              //弹幕自定义样式
              color: '#ff9500',
              fontSize: '20px',
              border: 'solid 1px #ff9500',
              borderRadius: '50px',
              padding: '5px 11px',
              backgroundColor: 'rgba(255, 255, 255, 0.1)'
            }
          }
        ],
        area: {
          start: 0,
          end: 1
        }
      },
      height: '100%',
      width: '100%',
      plugins: [CtrlPlugin],
      ctrlPlugin: {
        closeMin: () => {
          console.log('closeMin')
        },
        jumpAi: () => {
          console.log('jumpAi')
          navigate('/board')
        },
        fullScreen: (state: boolean) => {
          console.log('fullScreen', state)
          setFullScreen(state)
        },
        minFloat: (state: boolean) => {
          console.log('minFloat', state, location.pathname)
          if (!state && window.location.pathname !== '/course') {
            navigate('/course?play=1')
          }
          setMinFloat(state)
        }
      }
      // mp4plugin: {
      //   maxBufferLength: 50,
      //   minBufferLength: 10
      // }
    })
    playerRef.current.on('play', () => {
      playerRef.current?.unRegisterPlugin('tryPlugin')
      console.log('play')
      Util.findDom(document.getElementById('mse') as HTMLElement, '.xgplayer-start').className = 'xgplayer-start hide'
    })
    playerRef.current.on('pause', () => {
      console.log('pause')
      Util.findDom(document.getElementById('mse') as HTMLElement, '.xgplayer-start').className = 'xgplayer-start'
    })
    playerRef.current.on('ended', () => {
      console.log('ended')
      Util.findDom(document.getElementById('mse') as HTMLElement, '.xgplayer-start').className = 'xgplayer-start hide'

      const checkVideo = getCheckVideo()
      const checkCourse = getCheckCourse()
      const isLast = checkCourse?.videoList[checkCourse.videoList.length - 1].courseVideoId === checkVideo?.courseVideoId
      playerRef.current?.registerPlugin(TryPlugin, {
        isNext: !isLast,
        rePlay: () => {
          console.log('rePlay')
          playerRef.current?.replay()
        },
        jumpAi: () => {
          console.log('jumpAi')
          navigate('/board')
        },
        toNext: () => {
          console.log('toNext')
          const len = checkCourse?.videoList?.length || 0
          if (checkIndex < len - 1) {
            setCheckIndex((v) => v + 1)
            // setCheckVideo(nextVideo)
          }
        }
      })
    })
    playerRef.current.on('error', () => {
      console.log('error')
    })
    playerRef.current.on('timeupdate', () => {
      // console.log('timeupdate', data)
    })
  }, [])
  // console.log('checkVideo', checkVideo)
  useEffect(() => {
    console.log('checkVideo', checkVideo, playerRef.current, playerRef.current?.src)
    if (checkVideo) {
      playerRef.current?.seek(0)
      playerRef.current?.switchURL(checkVideo?.videoUrl || '')?.then(() => {
        playerRef.current?.play()
      })
    } else {
      console.log('pause 0')
      playerRef.current?.pause()
    }
  }, [checkVideo])

  useEffect(() => {
    console.log(location.pathname, minFloat)
    if (location.pathname === '/course' && !minFloat) {
      setIsCourse(true)
      setTimeout(() => {
        const position = document.getElementById('video_box')?.getBoundingClientRect()
        console.log('position', position)
        setBoxPosition(position)
      }, 500)
    }
    console.log(location.pathname, minFloat)
    if (location.pathname !== '/course' && !minFloat) {
      console.log('pause 1')
      playerRef.current?.pause()
      // @ts-ignore
      setBoxPosition({
        height: 0,
        width: 0,
        x: 0,
        y: 0
      })
    }
  }, [location.pathname])

  useUpdateEffect(() => {
    console.log('boxPosition', boxPosition)
    if (minFloat) {
      // @ts-ignore
      setBoxPosition({
        height: 205,
        width: 291,
        x: 0,
        y: 0
      })
    } else {
      const position = document.getElementById('video_box')?.getBoundingClientRect()
      console.log('position', position)
      setBoxPosition(position)
    }
  }, [minFloat])

  return (
    <Draggable
      cancel="strong"
      {...((fullScreen || isCourse) && !minFloat
        ? {
            onStart: () => false,
            position: {
              x: 0,
              y: 0
            }
          }
        : {})}
    >
      <div
        style={{
          width: fullScreen ? '100vw' : boxPosition?.width,
          height: fullScreen ? '100vh' : boxPosition?.height,
          top: fullScreen ? 0 : boxPosition?.top,
          left: fullScreen ? 0 : boxPosition?.left
        }}
        className={`fixed top-0 left-0 overflow-hidden ${fullScreen ? 'z-[999999] ' : 'rounded-[10px] z-[40]'}`}
      >
        <div id="mse"></div>
      </div>
    </Draggable>
  )
}
